import { TextField } from '@mui/material';
import CustomAutocomplete from '../../../components/customAutocomplete';
import { SurgicalUnit } from '../../../lib/types/surgicalUnit';
import { naturalOrder } from '../../../lib/utils';
import { ColumnId } from '../referralTableColumn';

type SpecialtyFilterProps = Readonly<{
  cellId: ColumnId;
  value: string | undefined | null;
  onFilterChange: (cellId: ColumnId, value: string | null) => void;
  surgicalUnits: SurgicalUnit[];
}>;

const NO_SPECIALTY = 'No specialty';

/**
 * Get value to pass to the autocomplete from the filter state
 *
 * Special cases:
 * - undefined => No selection (not filtering)
 * - null => Filtering by not having a specialty
 * - string starting with ! => Auto-filtering based on tab, should display as no selection
 */
const getInValue = (value: string | null | undefined) => {
  if (value === null) {
    return NO_SPECIALTY;
  }
  if (value === undefined) {
    return null;
  }
  if (value.startsWith('!')) {
    return null;
  }
  return value;
};

function SpecialtyFilter({ cellId, value, onFilterChange, surgicalUnits }: SpecialtyFilterProps) {
  return (
    <CustomAutocomplete
      name={`${cellId}Filter`}
      options={surgicalUnits
        .map((su) => su.surgicalUnitName)
        .sort(naturalOrder)
        .concat([NO_SPECIALTY])}
      onChange={(_, suName) => {
        if (suName === NO_SPECIALTY) {
          onFilterChange(cellId, null);
          return;
        }
        onFilterChange(cellId, suName ?? '');
      }}
      value={getInValue(value)}
      renderInput={(params) => <TextField {...params} size="small" />}
    />
  );
}

export default SpecialtyFilter;
