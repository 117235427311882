import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogProps, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  header: {
    fontSize: '20px',
    fontWeight: 'bold',
    padding: '1.5rem 1rem 1rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    textAlign: 'left',
  },
  closeButton: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    padding: '0 !important',
  },
}));

type CustomDialogProps = DialogProps & {
  title?: string | JSX.Element;
  children: preact.ComponentChildren;
  onSubmit?: () => void;
  closeBtn?: boolean;
};

/**
 * MUI Dialog with title, close button, and CTRL+Enter submit handler
 */
function CustomDialog(props: CustomDialogProps) {
  const { title, children, onSubmit, closeBtn, ...dialogProps } = props;
  const { open, onClose } = dialogProps;
  const classes = useStyles();

  const keyDownHandler = (e: KeyboardEvent) => {
    const { ctrlKey, key } = e;
    if (!ctrlKey || key !== 'Enter') {
      return;
    }
    if (onSubmit) {
      onSubmit();
    }
  };

  /** Temporarily disabled due to issues with behavior
  useEffect(() => {
    if (open) {
      document.addEventListener('keydown', keyDownHandler);
    } else {
      document.removeEventListener('keydown', keyDownHandler);
    }

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  */

  return (
    <Dialog {...dialogProps}>
      <div>
        {title && (
          <Typography variant="h1" className={classes.header}>
            {title}
          </Typography>
        )}
        {closeBtn && (
          <IconButton
            className={classes.closeButton}
            onClick={(e: any) => {
              if (onClose) {
                onClose(e, 'backdropClick');
              }
            }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        )}
        <div>{children}</div>
      </div>
    </Dialog>
  );
}

export default CustomDialog;
