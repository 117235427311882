import { CancelOutlined, CheckCircleOutlined, ChevronRight } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import TextsmsIcon from '@mui/icons-material/Textsms';
import { Box, Button, DialogContent, Switch, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { makeStyles } from '@mui/styles';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { useNavigate } from 'react-router-dom';
import CentredLoader from '../../../components/centredLoader';
import ControlledTextField, {
  ControlledFieldUpdateFn,
} from '../../../components/controlledTextField';
import CustomDialog from '../../../components/customDialog';
import LoadingButton from '../../../components/loadingButton';
import { useDispatchAlert } from '../../../hooks';
import StatusType from '../../../lib/status/statusType';
import { filterNullUndefined } from '../../../lib/tsUtils';
import { ProposedSurgeryField } from '../../../lib/types/DTO/fieldUpdateDTO';
import { ReferralListItem } from '../../../lib/types/referralListItem';
import { ReferralStatus } from '../../../lib/types/referralStatus';
import {
  dateString,
  dayJSDateTimeFormat,
  EMAIL_REGEX,
  formatDate,
  formatDateTimeUI,
  formatSurgeryDateTime,
  isValidDateTime,
  PHONE_REGEX,
  validateEmail,
} from '../../../lib/utils';
import useCommsService from '../../../services/useCommsService';
import useHQReviewService from '../../../services/useHQReviewService';
import usePatientService from '../../../services/usePatientService';
import useReferralService from '../../../services/useReferralService';
import EmailAlert from './emailAlert';
import OptOutAlert from './optOutAlert';
import ReferralQuestionnaires from './referralQuestionnaires';

const Field = {
  patientEmailAddress: 'patientEmailAddress',
  gpName: 'gpName',
  gpEmailAddress: 'gpEmailAddress',
  gpMobileNumber: 'gpMobileNumber',
  kinName: 'kinName',
  kinEmailAddress: 'kinEmailAddress',
  kinMobileNumber: 'kinMobileNumber',
  kinCorrespondence: 'kinCorrespondence',
  patientFirstName: 'patientFirstName',
  patientLastName: 'patientLastName',
  dateOfBirth: 'dateOfBirth',
  patientMobileNumber: 'patientMobileNumber',
  lastSmsDate: 'lastSmsDate',
  surgeryDate: 'surgeryDate',
  surgicalUnitName: 'surgicalUnitName',
  surgeryLocation: 'surgeryLocation',
} as const;

type FieldItem<T> = { value: T; error: boolean };

type PatientDetails = {
  patientEmailAddress?: FieldItem<string>;
  gpName?: FieldItem<string>;
  gpEmailAddress?: FieldItem<string>;
  gpMobileNumber?: FieldItem<string>;
  kinName?: FieldItem<string>;
  kinEmailAddress?: FieldItem<string>;
  kinMobileNumber?: FieldItem<string>;
  kinCorrespondence?: FieldItem<boolean>;
  patientFirstName?: FieldItem<string>;
  patientLastName?: FieldItem<string>;
  patientMobileNumber?: FieldItem<string>;
  dateOfBirth?: FieldItem<string>;
  surgicalUnitName?: FieldItem<string>;
  surgeryLocation?: FieldItem<string>;
};

function nullToUndefined<T>(item: T | null) {
  return item ? { value: item, error: false } : undefined;
}

const useStyles = makeStyles((theme) => ({
  rbtnGroup: {
    '& > *': {
      marginLeft: 8,
    },
  },
  btnBox: {
    marginTop: '0.25rem',
  },
  emailSuccess: {
    color: theme.palette.success.main,
  },
  dateInput: {
    flexGrow: 1,
    marginRight: '10px',
    '& .MuiIconButton-root': {
      marginRight: '0',
    },
  },
  iconAlign: {
    verticalAlign: -6,
  },
  inputTitle: {
    fontSize: '15px',
    fontWeight: 800,
    display: 'inline',
  },
}));

type ReferralDialogProps = {
  open: boolean;
  onClose: () => void;
  referralListItem: ReferralListItem;
};

type ReferralOptOutDetails = {
  sendReminders: ReferralListItem['sendReminders'];
  currentState: ReferralListItem['currentState'];
  lastTransitionDate: ReferralListItem['lastTransitionDate'];
  patientEmailState: ReferralListItem['patientEmailState'];
  patient: {
    phoneOnly: ReferralListItem['patient']['phoneOnly'];
    sendCorrespondence: ReferralListItem['patient']['phoneOnly'];
    optOutReason: ReferralListItem['patient']['optOutReason'];
  };
  remindersCancelReason: ReferralListItem['remindersCancelReason'];
};

function ReferralDialog({ open, onClose, referralListItem }: ReferralDialogProps) {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatchAlert = useDispatchAlert();

  const hqReviewService = useHQReviewService();
  const { getReferralByUuid, updateProposedSurgeryInfo } = useReferralService();
  const { getPatient, updatePatient, updatePatientKin, requestContactDetails } =
    usePatientService();
  const { getSmsListByGpReferralId } = useCommsService();

  const [patientDetails, setPatientDetails] = useState<PatientDetails>({});
  const [sendCorrespondence, setSendCorrespondence] = useState(true);
  const [phoneOnly, setPhoneOnly] = useState(false);
  const [surgeryDate, setSurgeryDate] = useState(referralListItem.surgeryDate);
  const [lastSmsDate, setLastSmsDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [sendingSMS, setSendingSMS] = useState(false);

  const [referralOptOutDetails, setReferralOptOutDetails] =
    useState<ReferralOptOutDetails>(referralListItem);

  const fetchData = useCallback(async () => {
    if (!referralListItem.gpReferralUuid) {
      return;
    }

    setLoading(true);

    Promise.all([
      getReferralByUuid(referralListItem.gpReferralUuid),
      getPatient(referralListItem.patient.patientUuid),
    ])
      .then(([referralRes, patientRes]) => {
        setPatientDetails({
          [Field.patientEmailAddress]: nullToUndefined(referralRes.patientEmailAddress),
          [Field.gpName]: { value: referralRes.gpName, error: false },
          [Field.gpEmailAddress]: nullToUndefined(referralRes.gpEmailAddress),
          [Field.gpMobileNumber]: nullToUndefined(referralRes.gpMobileNumber),
          [Field.kinName]: nullToUndefined(referralRes.kinName),
          [Field.kinEmailAddress]: { value: referralRes.kinEmailAddress ?? '', error: false },
          [Field.kinMobileNumber]: nullToUndefined(referralRes.kinMobileNumber),
          [Field.kinCorrespondence]: nullToUndefined(referralRes.kinCorrespondence),
          [Field.patientFirstName]: { value: patientRes.firstName, error: false },
          [Field.patientLastName]: { value: patientRes.lastName, error: false },
          [Field.patientMobileNumber]: { value: patientRes.phone, error: false },
          [Field.dateOfBirth]: { value: patientRes.dateOfBirth as any, error: false },
          [Field.surgicalUnitName]: nullToUndefined(referralRes.surgicalUnitName),
          [Field.surgeryLocation]: nullToUndefined(referralRes.surgeryLocation),
        });
        setSurgeryDate(referralRes.surgeryDate);
        setSendCorrespondence(referralRes.sendReminders && patientRes.sendCorrespondence);
        setPhoneOnly(patientRes.phoneOnly);

        setReferralOptOutDetails({
          sendReminders: referralRes.sendReminders,
          currentState: referralRes.currentState,
          lastTransitionDate: referralRes.lastTransitionDate,
          // Email state won't update, since it is only part of ReferralListItem
          patientEmailState: referralListItem.patientEmailState,
          patient: {
            phoneOnly: patientRes.phoneOnly,
            sendCorrespondence: patientRes.sendCorrespondence,
            optOutReason: patientRes.optOutReason,
          },
          remindersCancelReason: referralRes.remindersCancelReason,
        });
      })
      .catch(() => dispatchAlert('Failed to fetch patient information'))
      .finally(() => setLoading(false));

    getSmsListByGpReferralId(referralListItem.gpReferralUuid)
      .then((res) => {
        const smsData = res?.length > 0 ? res[res.length - 1] : null;
        setLastSmsDate(smsData?.sentAt);
      })
      .catch(() => dispatchAlert('Failed to fetch patient SMS history'));
  }, [referralListItem, getReferralByUuid, getPatient, getSmsListByGpReferralId, dispatchAlert]);

  // Load data when referral is selected
  useEffect(() => {
    if (referralListItem && open) {
      fetchData();
    }
    if (!open) {
      // Don't display previous details
      setLoading(true);
    }
  }, [referralListItem, open, fetchData]);

  useEffect(() => {
    // Disable kin correspondence if email isn't provided
    if (
      patientDetails.kinCorrespondence?.value === true &&
      (patientDetails.kinEmailAddress?.value?.length ?? 0) <= 0
    ) {
      setPatientDetails({
        ...patientDetails,
        [Field.kinCorrespondence]: { value: false, error: false },
      });
    }
  }, [patientDetails]);

  const handleClose = () => {
    setEditing(false);
    onClose();
  };

  const handleSkip = async (referralUuid: string) => {
    await hqReviewService.updateStatus(referralUuid, {
      statusType: StatusType.Workflow,
      newStatus: ReferralStatus.HQReceived,
    });
    navigate(`/referral/${referralListItem.gpReferralUuid}`);
  };

  const handleSendQuestionnaire = (referralUuid: string) => {
    hqReviewService
      .createQuestionnaire(referralUuid)
      .then(() => dispatchAlert('Questionnaire sent', false))
      .catch(() => dispatchAlert('Failed to send questionnaire'))
      .finally(() => handleClose());
  };

  const handleSendRequestDetails = async (referralUuid: string) => {
    setSendingSMS(true);

    requestContactDetails(referralUuid)
      .then(() => dispatchAlert('Patient details request sent', false))
      .catch(() => dispatchAlert('Failed to send SMS to patient'))
      .finally(() => {
        handleClose();
        setSendingSMS(false);
      });
  };

  const handleUpdateItem: ControlledFieldUpdateFn = ({ name, value, error }) =>
    setPatientDetails({ ...patientDetails, [name]: { value, error } });

  const handleSubmit = () => {
    const payload: Record<string, any> = {};

    Object.entries(patientDetails).forEach(([key, item]: [string, any]) => {
      if (item) {
        payload[key] = item.value;
      }
    });

    const updates = [
      updatePatient(referralListItem.patient.patientUuid, payload),
      updatePatientKin(
        referralListItem.patient.patientUuid,
        referralListItem.gpReferralUuid,
        payload,
      ),
    ];

    if (surgeryDate !== referralListItem.surgeryDate) {
      const updateData = [
        {
          field: ProposedSurgeryField.surgeryDate,
          newValue: surgeryDate,
          newLabel: surgeryDate ? dateString(surgeryDate) : '',
        },
      ];
      updates.push(updateProposedSurgeryInfo(updateData, referralListItem.gpReferralUuid));
    }

    Promise.all(updates)
      .then(() => fetchData())
      .then(() => dispatchAlert('Updated patient information', false))
      .catch(() => dispatchAlert('Failed to update patient information'));
  };

  const handleEditButton = () => {
    const surgeryDateValid = !surgeryDate || isValidDateTime(surgeryDate);
    const isFieldsValid =
      !Object.values(patientDetails)
        .filter(filterNullUndefined)
        .map(({ error }) => error)
        .includes(true) && surgeryDateValid;

    if (editing && !isFieldsValid) {
      dispatchAlert('Fields are invalid');
    } else if (editing && isFieldsValid) {
      setEditing((prevState) => !prevState);
      handleSubmit();
    } else {
      setEditing((prevState) => !prevState);
    }
  };

  const awaitingHQReceipt = (state: ReferralStatus) =>
    state === ReferralStatus.WaitingList ||
    state === ReferralStatus.HQSent ||
    state === ReferralStatus.DetailsRequested;

  const onSubmit = () => {
    if (editing) {
      handleEditButton();
    } else {
      handleSendQuestionnaire(referralListItem.gpReferralUuid);
    }
  };

  const handleDOBChange = (newDOB: Date) => {
    handleUpdateItem({
      name: Field.dateOfBirth,
      value: newDOB,
      error: newDOB == null,
    });
  };

  const hasValidPatientEmail = validateEmail(patientDetails.patientEmailAddress?.value ?? '');
  const hasValidNOKEmail =
    patientDetails.kinCorrespondence?.value &&
    validateEmail(patientDetails.kinEmailAddress?.value ?? '');

  const hasValidEmail = (hasValidPatientEmail || hasValidNOKEmail) ?? false;

  return (
    <CustomDialog
      open={open}
      maxWidth="md"
      fullWidth
      title={
        <>
          Referral Details
          <Button
            style={{ marginLeft: '1rem' }}
            variant="contained"
            color="primary"
            size="small"
            startIcon={!editing ? <EditIcon fontSize="medium" /> : <DoneIcon fontSize="medium" />}
            onClick={() => handleEditButton()}
          >
            {!editing ? 'Edit' : 'Save'}
          </Button>
        </>
      }
      onClose={handleClose}
      onSubmit={onSubmit}
    >
      {loading ? (
        <Box
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '650px',
            minHeight: '340px',
          }}
        >
          <CentredLoader loading />
        </Box>
      ) : (
        <DialogContent>
          <OptOutAlert referral={referralOptOutDetails} />

          <Grid container>
            <Grid size={{ md: 6, sm: 12 }}>
              <Box sx={{ mb: 2 }}>
                <Grid name="urContainer">
                  <b>UR:</b> {referralListItem.patient.patientUR}
                </Grid>
                <Grid name="nameContainer">
                  {editing ? (
                    <>
                      <ControlledTextField
                        item={{
                          value: patientDetails.patientFirstName?.value ?? '',
                          label: 'First Name',
                          name: Field.patientFirstName,
                        }}
                        editing={editing}
                        updateItem={handleUpdateItem}
                        validation={{ required: true }}
                        helperText="First name is required."
                      />
                      <ControlledTextField
                        item={{
                          value: patientDetails.patientLastName?.value ?? '',
                          label: 'Last Name',
                          name: Field.patientLastName,
                        }}
                        editing={editing}
                        updateItem={handleUpdateItem}
                        validation={{ required: true }}
                        helperText="Last name is required."
                      />
                    </>
                  ) : (
                    <>
                      <b>Name:</b>{' '}
                      {`${patientDetails.patientFirstName?.value} ${patientDetails.patientLastName?.value}`}
                    </>
                  )}
                </Grid>
                <Grid name="dobContainer">
                  {editing ? (
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                      <b style={{ marginRight: '10px' }}>DOB: </b>
                      <DatePicker<Dayjs>
                        disableFuture
                        timezone="UTC" // Required to avoid shifting date, since this is stored without a time component
                        format="DD/MM/YYYY"
                        value={dayjs(patientDetails.dateOfBirth?.value ?? null)}
                        onChange={(date: Dayjs) => handleDOBChange(date.toDate())}
                        slotProps={{
                          textField: {
                            variant: 'standard',
                            margin: 'dense',
                          },
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      <b>DOB: </b>
                      {formatDate(patientDetails.dateOfBirth?.value ?? null)}
                    </>
                  )}
                </Grid>
                <Grid name="emailContainer">
                  <ControlledTextField
                    item={{
                      value: patientDetails.patientEmailAddress?.value,
                      label: 'Email',
                      name: Field.patientEmailAddress,
                    }}
                    editing={editing}
                    updateItem={handleUpdateItem}
                    validation={{
                      required: false,
                      pattern: EMAIL_REGEX,
                    }}
                    helperText="Email should be valid"
                  />
                  {!phoneOnly && (
                    <EmailAlert
                      emailState={referralOptOutDetails.patientEmailState}
                      lastSmsDate={lastSmsDate ?? undefined}
                      referralState={referralOptOutDetails.currentState}
                      lastTransitionDate={referralOptOutDetails.lastTransitionDate}
                    />
                  )}
                </Grid>
                <Grid container>
                  <Grid name="mobileContainer">
                    <ControlledTextField
                      item={{
                        value: patientDetails.patientMobileNumber?.value,
                        label: 'Mobile',
                        name: Field.patientMobileNumber,
                      }}
                      editing={editing}
                      updateItem={handleUpdateItem}
                      validation={{
                        required: false,
                        pattern: PHONE_REGEX,
                      }}
                      helperText="Phone number should be valid"
                    />
                  </Grid>
                  <Grid style={{ paddingLeft: '20px' }}>
                    {lastSmsDate != null && (
                      <Tooltip
                        title={`Last SMS sent to patient on ${formatDateTimeUI(lastSmsDate)}`}
                      >
                        <TextsmsIcon fontSize="medium" colour="secondary" />
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid size={{ md: 6, sm: 12 }}>
              <Box sx={{ mb: 2 }}>
                <Grid name="procedureContainer">
                  <b>Procedure:</b> {referralListItem.procedureName}
                </Grid>
                <Grid name="surgeryDateContainer">
                  {editing ? (
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                      <b style={{ marginRight: '10px' }}>Date:</b>
                      <DateTimePicker<Dayjs>
                        format={dayJSDateTimeFormat}
                        value={surgeryDate ? dayjs(surgeryDate) : null}
                        onChange={(date: Dayjs | null) =>
                          setSurgeryDate(date?.isValid() ? date.toDate() : null)
                        }
                        slotProps={{
                          field: { clearable: true },
                          textField: {
                            variant: 'standard',
                            margin: 'dense',
                          },
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      <b>Date:</b> {formatSurgeryDateTime(surgeryDate as any)}
                    </>
                  )}
                </Grid>
                <Grid name="surgeryLocationContainer">
                  <b>Location:</b> {patientDetails.surgeryLocation?.value}
                </Grid>
                <Grid name="surgicalUnitContainer">
                  <b>Unit:</b> {patientDetails.surgicalUnitName?.value}
                </Grid>
              </Box>
            </Grid>
            <Grid size={{ md: 6, sm: 12 }}>
              <Box sx={{ mb: 2 }}>
                <Grid name="gpNameContainer">
                  <ControlledTextField
                    item={{
                      value: patientDetails.gpName?.value,
                      label: 'GP Name',
                      name: Field.gpName,
                    }}
                    editing={editing}
                    updateItem={handleUpdateItem}
                    validation={{ required: false }}
                  />
                </Grid>
                <Grid name="gpEmailContainer">
                  <ControlledTextField
                    item={{
                      value: patientDetails.gpEmailAddress?.value,
                      label: 'GP Email',
                      name: Field.gpEmailAddress,
                    }}
                    editing={editing}
                    updateItem={handleUpdateItem}
                    validation={{
                      required: false,
                      pattern: EMAIL_REGEX,
                    }}
                    helperText="Email should be valid"
                  />
                </Grid>
                <Grid name="gpMobileContainer">
                  <ControlledTextField
                    item={{
                      value: patientDetails.gpMobileNumber?.value,
                      label: 'GP Mobile',
                      name: Field.gpMobileNumber,
                    }}
                    editing={editing}
                    updateItem={handleUpdateItem}
                    validation={{
                      required: false,
                      pattern: PHONE_REGEX,
                    }}
                    helperText="Phone number should be valid"
                  />
                </Grid>
              </Box>
            </Grid>
            <Grid size={{ md: 6, sm: 12 }}>
              <Box sx={{ mb: 2 }}>
                <Grid name="kinNameContainer">
                  <ControlledTextField
                    item={{
                      value: patientDetails.kinName?.value,
                      label: 'Kin Name',
                      name: Field.kinName,
                    }}
                    editing={editing}
                    updateItem={handleUpdateItem}
                    validation={{ required: false }}
                  />
                </Grid>
                <Grid name="kinEmailContainer">
                  <ControlledTextField
                    item={{
                      value: patientDetails.kinEmailAddress?.value ?? '',
                      label: 'Kin Email',
                      name: Field.kinEmailAddress,
                    }}
                    editing={editing}
                    updateItem={handleUpdateItem}
                    validation={{
                      required: false,
                      pattern: EMAIL_REGEX,
                    }}
                    helperText="Email should be valid"
                  />
                </Grid>
                <Grid name="kinMobileContainer">
                  <ControlledTextField
                    item={{
                      value: patientDetails.kinMobileNumber?.value ?? '',
                      label: 'Kin Mobile',
                      name: Field.kinMobileNumber,
                    }}
                    editing={editing}
                    updateItem={handleUpdateItem}
                    validation={{
                      required: false,
                      pattern: PHONE_REGEX,
                    }}
                    helperText="Phone number should be valid"
                  />
                </Grid>

                <Grid name="kinCorrespondenceContainer">
                  {editing && (
                    <>
                      <Typography variant="body1" className={classes.inputTitle}>
                        Kin receives correspondence{' '}
                      </Typography>

                      <Switch
                        checked={!!patientDetails.kinCorrespondence?.value}
                        disabled={(patientDetails.kinEmailAddress?.value?.length ?? 0) === 0}
                        onChange={(_e: any, value: boolean) => {
                          handleUpdateItem({
                            value,
                            name: Field.kinCorrespondence,
                            error: false,
                          });
                        }}
                      />
                    </>
                  )}
                  {!editing && (
                    <>
                      <Typography variant="body1" className={classes.inputTitle}>
                        Kin receives correspondence{' '}
                      </Typography>
                      {patientDetails.kinCorrespondence?.value ? (
                        <CheckCircleOutlined color="secondary" className={classes.iconAlign} />
                      ) : (
                        <CancelOutlined color="error" className={classes.iconAlign} />
                      )}
                    </>
                  )}
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <ReferralQuestionnaires referralUuid={referralListItem.gpReferralUuid} />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <Tooltip title="Proceed to referral details">
              <Button
                endIcon={<ChevronRight />}
                onClick={() => handleSkip(referralListItem.gpReferralUuid)}
              >
                SKIP
              </Button>
            </Tooltip>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'end' }} className={classes.btnBox}>
            <Grid className={classes.rbtnGroup}>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>

              <LoadingButton
                variant="outlined"
                color="secondary"
                onClick={() => handleSendRequestDetails(referralListItem.gpReferralUuid)}
                disabled={!patientDetails.patientMobileNumber?.value || editing}
                loading={sendingSMS}
              >
                Send SMS to Request Details
              </LoadingButton>

              {awaitingHQReceipt(referralOptOutDetails.currentState) && (
                <Tooltip title={!sendCorrespondence ? 'Patient has opted out of emails' : ''}>
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSendQuestionnaire(referralListItem.gpReferralUuid)}
                      disabled={!sendCorrespondence || !hasValidEmail || editing}
                    >
                      {[ReferralStatus.WaitingList, ReferralStatus.DetailsRequested].includes(
                        referralOptOutDetails.currentState,
                      )
                        ? 'Send Questionnaire'
                        : 'Resend Questionnaire'}
                    </Button>
                  </span>
                </Tooltip>
              )}
            </Grid>
          </Box>
        </DialogContent>
      )}
    </CustomDialog>
  );
}

export default ReferralDialog;
