import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { TabContext } from '@mui/lab';
import {
  Box,
  Button,
  LabelDisplayedRowsArgs,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { debounce } from 'lodash';
import moment from 'moment-timezone';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import CentredLoader from '../../../components/centredLoader';
import {
  handleErrorInFunctional,
  processErrorResponseInFunctional,
} from '../../../components/errorBoundary/errorHandlingUtils';
import RootContainer from '../../../components/rootContainer';
import { filterNullUndefined } from '../../../lib/tsUtils';
import { ClinicianStatus } from '../../../lib/types/clinicianStatus';
import Feature from '../../../lib/types/feature';
import { ReferralAlert } from '../../../lib/types/referralAlerts';
import { ReferralStatus } from '../../../lib/types/referralStatus';
import { SurgicalUnit, SurgicalUnitNames } from '../../../lib/types/surgicalUnit';
import { TestStatus } from '../../../lib/types/testStatus';
import { maxDate } from '../../../lib/utils';
import useHqSettingService from '../../../services/useHQSettingsService';
import usePatientWorkflowService from '../../../services/usePatientWorkflowService';
import useRiskAlertsService from '../../../services/useRiskAlertsService';
import useSurgicalUnitService from '../../../services/useSurgicalUnitService';
import { StoreContext } from '../../../store';
import FilterButtonGroup from '../filterButtonGroup';
import NaturalLanguageQuery from '../naturalLanguageQuery/naturalLanguageQuery';
import ReferralTableBody from '../referralTableBody';
import { ColumnId, DateRangeValues, riskColumnMap } from '../referralTableColumn';
import DeleteReferralDialog from '../referralTableDialogs/deleteReferralDialog';
import ReferralDialog from '../referralTableDialogs/referralDialog';
import SendHQDialog from '../referralTableDialogs/sendHQDialog';
import ReferralTableHead from '../referralTableHead';
import TabSelector, { FilterOptionKey, TabOptionData, TabOptionKey } from '../tabSelector';
import { FilterOptions, FilterParams, ReferralTableItem, SortOption } from '../types';
import useDispatchFilter from '../useDispatchFilter';
import ReferralListCount from './referralListCount';

const useStyles = makeStyles((theme) => ({
  tabPanel: {
    backgroundColor: '#fff',
    borderTopLeftRadius: 9,
    borderTopRightRadius: 9,
    marginBottom: theme.spacing(2),
  },
  tabList: {
    borderBottom: '1px solid #E7E7E7',
  },
  filterButtons: {},
  container: {
    minHeight: '50vh',
    '& table tr:first-child td:first-child': { borderTopLeftRadius: '9px' },
    '& table tr:last-child td:first-child': { borderBottomLeftRadius: '9px' },
  },
  tableBody: {
    cursor: 'pointer',
  },
}));

type Props = Readonly<{
  showTabs?: boolean;
  columns: ColumnId[];
  filterOptions: FilterParams;
  onFilterChange: (filters: FilterParams) => void;
  referralDetailsURI?: 'referral' | 'prediction';
  featureList: Feature[];
  tabOptions: Record<TabOptionKey, TabOptionData>;
}>;

function ReferralList({
  filterOptions,
  onFilterChange,
  referralDetailsURI = 'referral',
  columns,
  showTabs = false,
  featureList,
  tabOptions,
}: Props) {
  const navigate = useNavigate();
  const classes = useStyles();

  const [{ ribbonFilters, selectedTab }] = useContext(StoreContext);
  const { getPatientWorkflows, getPatientWorkflowsCount } = usePatientWorkflowService();
  const { getAllSurgicalUnits } = useSurgicalUnitService();
  const { getPostOpSettings } = useHqSettingService();
  const { getRiskItems } = useRiskAlertsService();

  const [referralLoading, setReferralLoading] = useState(false);
  const [countLoading, setCountLoading] = useState(false);
  const [errorStatus, setErrorStatus] = useState(null);

  const [totalReferralCount, setTotalReferralCount] = useState(0);
  const [referralSummary, setReferralSummary] = useState<ReferralTableItem[]>([]);
  const [selectedReferrals, setSelectedReferrals] = useState<ReferralTableItem[]>([]);
  const [clickedReferral, setClickedReferral] = useState<ReferralTableItem | null>(null);

  const [referralDialogOpen, setReferralDialogOpen] = useState(false);
  const [sendHQDialogOpen, setSendHQDialogOpen] = useState(false);
  const [deleteReferralDialogOpen, setDeleteReferralDialogOpen] = useState(false);

  const [surgicalUnits, setSurgicalUnits] = useState<SurgicalUnit[]>([]);
  const [totalPostOpWindow, setTotalPostOpWindow] = useState(0);
  const [manualSurgeryDate, setManualSurgeryDate] = useState<DateRangeValues | null>(null);

  const [enabledColumns, setEnabledColumns] = useState<ColumnId[]>([]);

  const { dispatchTab, dispatchRibbonFilters } = useDispatchFilter();

  // Main function to get the referrals
  const fetchReferrals = useMemo(
    () =>
      debounce(async (params: FilterParams, signal: AbortSignal) => {
        try {
          const referrals = await getPatientWorkflows(params, { signal });
          referrals.forEach((ref) => {
            ref.isSelected = false; // eslint-disable-line no-param-reassign
          });
          setReferralSummary(referrals);
          setReferralLoading(false);

          const referralCount = await getPatientWorkflowsCount(params, {
            signal,
          });
          setTotalReferralCount(referralCount);
          setCountLoading(false);
        } catch (e) {
          processErrorResponseInFunctional(e, setErrorStatus, signal);
        } finally {
          setReferralLoading(false);
          setCountLoading(false);
        }
      }, 300),
    [],
  );

  // When to call function to get list of referrals
  useEffect(() => {
    const controller = new AbortController();

    if (!referralDialogOpen && !sendHQDialogOpen && !deleteReferralDialogOpen) {
      setReferralLoading(true);
      setCountLoading(true);
      setSelectedReferrals([]);

      let { state } = filterOptions.filter;
      if (!state || state.length === 0) {
        state = tabOptions[selectedTab].filter.workflow ?? [];
      }

      fetchReferrals(
        { ...filterOptions, filter: { ...filterOptions.filter, state } },
        controller.signal,
      );
    }

    return () => controller.abort('');
  }, [
    deleteReferralDialogOpen,
    fetchReferrals,
    filterOptions,
    referralDialogOpen,
    selectedTab,
    sendHQDialogOpen,
    tabOptions,
  ]);

  useEffect(() => {
    setReferralDialogOpen(clickedReferral !== null);
    if (
      clickedReferral &&
      ![
        ReferralStatus.WaitingList,
        ReferralStatus.HQSent,
        ReferralStatus.DetailsRequested,
      ].includes(clickedReferral.currentState)
    ) {
      navigate(`/${referralDetailsURI}/${clickedReferral.gpReferralUuid}`);
    }
  }, [clickedReferral, referralDetailsURI]);

  const tabSurgeryDateValues = (tab: TabOptionKey, currentDateRange: DateRangeValues | null) => {
    if (tab === TabOptionKey.All || tab === TabOptionKey.AllFilters) {
      // If we are only filtering out past dates, remove the filter for this tab
      if (!currentDateRange?.endDate) {
        return null;
      }
      return currentDateRange;
    }

    if (tab === TabOptionKey.PostOp) {
      return {
        startDate: moment().subtract(totalPostOpWindow, 'days').toDate(),
        endDate: new Date(),
      };
    }

    if (!currentDateRange) {
      return { startDate: new Date() };
    }

    // If date is completely in the past, remove the filter
    if (currentDateRange.endDate && currentDateRange.endDate < new Date()) {
      return { startDate: new Date() };
    }

    // Reset start date if it's in the past. Set end date to match if it's before start date
    const startDate = maxDate([new Date(), currentDateRange.startDate]) ?? new Date();
    const endDate = currentDateRange.endDate && maxDate([startDate, currentDateRange.endDate]);
    return { startDate, endDate };
  };

  /**
   * Initialise surgery date filter on current tab
   * Keep any existing filter options
   */
  const handleTabInit = (tab: TabOptionKey) => {
    const surgeryDate = tabSurgeryDateValues(tab, manualSurgeryDate) ?? undefined;
    onFilterChange({
      ...filterOptions,
      filter: {
        ...filterOptions.filter,
        surgeryDate,
      },
    });
  };

  const handleTabReset = (tab: TabOptionKey) => {
    setManualSurgeryDate(null);
    onFilterChange({
      ...filterOptions,
      page: 0,
      filter: {
        state: [],
        surgeryDate: tabSurgeryDateValues(tab, null) ?? undefined,
      },
    });
    setClickedReferral(null);
    dispatchTab(tab);
    dispatchRibbonFilters(new Map());
  };

  const handleTabChange = (tab: TabOptionKey) => {
    // Keep column filters and sort options when switching tabs.
    // Screen columns aren't available on all tabs, so they are reset
    const { name, ur, surgicalUnitName, triageCategory, waitlistDate } = filterOptions.filter;
    onFilterChange({
      ...filterOptions,
      page: 0,
      filter: {
        name,
        ur,
        surgicalUnitName,
        triageCategory,
        waitlistDate,
        state: [],
        surgeryDate: tabSurgeryDateValues(tab, manualSurgeryDate) ?? undefined,
      },
    });
    setClickedReferral(null);

    dispatchTab(tab);
    dispatchRibbonFilters(new Map());
  };

  // Set initial data on mount
  useEffect(() => {
    const fetchData = async () => {
      const [units, risks, settings] = await Promise.all([
        getAllSurgicalUnits(),
        getRiskItems(),
        getPostOpSettings(),
      ]);

      setSurgicalUnits(units);

      const disabledColumns = risks
        .filter((r) => !r.visible || !r.screenEnabled)
        .map((r) => riskColumnMap[r.riskType])
        .filter(filterNullUndefined);
      setEnabledColumns(columns.filter((col) => !disabledColumns.includes(col)));

      setTotalPostOpWindow(settings.windowDays + settings.sendDelayDays);
    };
    fetchData();
  }, [getRiskItems, columns, getAllSurgicalUnits, getPostOpSettings]);

  useEffect(() => {
    handleTabInit(selectedTab);
  }, [totalPostOpWindow]);

  /**
   * handles when one of the quick filter buttons in the ribbon is clicked
   * @param key id of the button
   * @param filterGroupName which group of filters the key belongs to
   */
  const handleFilterButtonChange = (key: string, filterGroupName: FilterOptionKey) => {
    // Unselect ribbon button if already selected
    const selected =
      filterGroupName === 'workflow'
        ? ribbonFilters.get(filterGroupName)?.includes(key)
        : ribbonFilters.get(filterGroupName) === key;
    const value = selected ? null : key;
    const updatedFilters = new Map<FilterOptionKey, string[] | string | null>(
      ribbonFilters.set(filterGroupName, value),
    );

    const workflowState = updatedFilters.get('workflow') as ReferralStatus;
    const state = workflowState ? [workflowState] : [];
    const alerts = ribbonFilters.get('alerts') as ReferralAlert;
    const testState = ribbonFilters.get('test') as TestStatus;
    const drReviewState = ribbonFilters.get('clinician') as ClinicianStatus;

    onFilterChange({
      ...filterOptions,
      page: 0,
      filter: { ...filterOptions.filter, state, alerts, testState, drReviewState },
    });
    dispatchRibbonFilters(updatedFilters);
  };

  const applyNaturalQueryFilters = (filters: FilterParams) => {
    const { filter } = filters;

    handleTabChange(TabOptionKey.AllFilters);

    onFilterChange(filters);

    const updatedMap = new Map(ribbonFilters.set('workflow', filter?.state ?? null));
    if (filter?.testState) {
      updatedMap.set('test', filter.testState);
    }
    if (filter?.drReviewState) {
      updatedMap.set('clinician', filter.drReviewState);
    }
    if (filter?.alerts) {
      updatedMap.set('alerts', filter.alerts);
    }

    dispatchRibbonFilters(updatedMap);
  };

  const handleCheckAllToggle = (checked: boolean) => {
    referralSummary.forEach((ref) => {
      // eslint-disable-next-line no-param-reassign
      ref.isSelected = checked;
    });
    if (checked) {
      setSelectedReferrals(referralSummary);
    } else {
      setSelectedReferrals([]);
    }
  };

  const handleColumnSort = (sortOption: SortOption) =>
    onFilterChange({
      ...filterOptions,
      orderBy: sortOption.orderBy,
      order: sortOption.order,
    });

  const handleFilterChange = useCallback(
    (column: ColumnId, filter: Partial<FilterOptions>) => {
      if (column === 'surgeryDate') {
        const { surgeryDate } = filter;
        if (surgeryDate?.startDate || surgeryDate?.endDate) {
          setManualSurgeryDate(surgeryDate);
        } else {
          setManualSurgeryDate(null);
        }
      }

      onFilterChange({ ...filterOptions, page: 0, filter });
      if (column === 'state') {
        dispatchRibbonFilters(new Map(ribbonFilters.set('workflow', null)));
      }
    },
    [dispatchRibbonFilters, filterOptions, onFilterChange, ribbonFilters],
  );

  const handleCheckRowToggle = (referral: ReferralTableItem, checked: boolean) => {
    // eslint-disable-next-line no-param-reassign
    referral.isSelected = checked;
    if (checked) {
      setSelectedReferrals([...selectedReferrals, referral]);
    } else {
      setSelectedReferrals(referralSummary.filter((ref) => ref.isSelected));
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const key = (event.target as HTMLInputElement).value;
    onFilterChange({ ...filterOptions, rowsPerPage: +key, page: 0 });
  };

  const referralListCount = (paginationInfo: LabelDisplayedRowsArgs) => (
    <ReferralListCount paginationInfo={paginationInfo} showSpinner={countLoading} />
  );

  const getSurgicalUnits = () =>
    featureList.includes(Feature.ENDOSCOPY)
      ? surgicalUnits.filter((su) => su.surgicalUnitName !== SurgicalUnitNames.Endoscopy)
      : surgicalUnits;

  handleErrorInFunctional(errorStatus, setErrorStatus);

  return (
    <>
      <RootContainer>
        <Helmet title="Referral list" />
        {showTabs && (
          <TabContext value={selectedTab}>
            <div className={classes.tabPanel}>
              <Box className={classes.tabList}>
                <TabSelector onTabChange={handleTabChange} />
              </Box>

              <Box className={classes.filterButtons}>
                <FilterButtonGroup
                  selectedFilters={ribbonFilters}
                  onFilterChange={handleFilterButtonChange}
                  featureList={featureList}
                  tabOptions={tabOptions}
                />
              </Box>
            </div>
          </TabContext>
        )}

        {featureList.includes(Feature.LLM_COMPLETION_REFERRAL_LIST) && (
          <NaturalLanguageQuery
            onApplyFilters={applyNaturalQueryFilters}
            filterOptions={filterOptions}
          />
        )}

        <Paper elevation={0}>
          <TableContainer className={classes.container}>
            <Table style={{ borderCollapse: 'separate' }}>
              <TableHead>
                <ReferralTableHead
                  columns={enabledColumns}
                  displayedRowCount={filterOptions.rowsPerPage}
                  selectedRowCount={selectedReferrals.length}
                  onCheckAllChange={handleCheckAllToggle}
                  sortOption={{
                    orderBy: filterOptions.orderBy,
                    order: filterOptions.order,
                  }}
                  onColumnSortChange={handleColumnSort}
                  filterOptions={filterOptions.filter}
                  onFilterChange={handleFilterChange}
                  currentTab={selectedTab}
                  onDeleteClick={() => setDeleteReferralDialogOpen(true)}
                  onSendHQClick={() => setSendHQDialogOpen(true)}
                  surgicalUnits={getSurgicalUnits()}
                  featureList={featureList}
                />
              </TableHead>
              <TableBody className={classes.tableBody}>
                <CentredLoader loading={referralLoading} />
                <ReferralTableBody
                  columns={enabledColumns}
                  referrals={referralSummary}
                  currentTab={selectedTab}
                  onRowChecked={handleCheckRowToggle}
                  onRowClicked={setClickedReferral}
                  featureList={featureList}
                />
              </TableBody>
            </Table>

            {!referralLoading && referralSummary.length === 0 && (
              <Box sx={{ textAlign: 'center', mt: 10 }}>
                <ErrorOutlineIcon
                  sx={{ mr: '5px', display: 'inline', verticalAlign: 'bottom' }}
                  color="secondary"
                />
                <Typography sx={{ display: 'inline' }}>
                  No referrals match current filters
                </Typography>

                <Typography sx={{ mt: 1.5 }}>
                  <Button variant="contained" onClick={() => handleTabReset(selectedTab)}>
                    Clear filters
                  </Button>
                </Typography>
              </Box>
            )}
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={countLoading ? -1 : totalReferralCount}
            labelDisplayedRows={referralListCount}
            rowsPerPage={filterOptions.rowsPerPage}
            page={filterOptions.page}
            onPageChange={(_: any, page: number) => onFilterChange({ ...filterOptions, page })}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </RootContainer>

      {[TabOptionKey.Todo, TabOptionKey.All, TabOptionKey.AllFilters].includes(selectedTab) && (
        <>
          {clickedReferral != null && (
            <ReferralDialog
              open={referralDialogOpen}
              referralListItem={clickedReferral}
              onClose={() => setReferralDialogOpen(false)}
            />
          )}

          <SendHQDialog
            open={sendHQDialogOpen}
            selectedReferrals={selectedReferrals}
            onClose={() => setSendHQDialogOpen(false)}
            onError={setErrorStatus}
          />
        </>
      )}
      <DeleteReferralDialog
        open={deleteReferralDialogOpen}
        selectedReferrals={selectedReferrals}
        onClose={() => setDeleteReferralDialogOpen(false)}
        onError={setErrorStatus}
      />
    </>
  );
}

export default ReferralList;
