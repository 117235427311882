import { useCallback } from 'react';
import useSecureAPI from '../auth/useSecureAPI';
import { SurgicalUnit } from '../lib/types/surgicalUnit';

function useSurgicalUnitService() {
  const { get, put, post, deleteReq, loading } = useSecureAPI('surgicalUnits');

  const getAllSurgicalUnits = useCallback(() => get<SurgicalUnit[]>(''), [get]);
  const addSurgicalUnit = useCallback(
    (surgicalUnitName: string) => post<SurgicalUnit>('', { surgicalUnitName }),
    [post],
  );
  const updateSurgicalUnit = useCallback(
    (surgicalUnitId: number, payload: SurgicalUnit) => put(`${surgicalUnitId}`, payload),
    [put],
  );
  const deleteSurgicalUnit = useCallback(
    (surgicalUnitId: number) => deleteReq(`${surgicalUnitId}`),
    [deleteReq],
  );

  return {
    getAllSurgicalUnits,
    addSurgicalUnit,
    updateSurgicalUnit,
    deleteSurgicalUnit,
    loading,
  };
}

export default useSurgicalUnitService;
