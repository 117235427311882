import { AsType } from '../../tsUtils';

export const ProposedSurgeryField = {
  surgeryDate: 'surgeryDate',
  procedure: 'procedure',
  location: 'location',
  surgeon: 'surgeon',
  anaesthetist: 'anaesthetist',
  dischargeDestination: 'dischargeDestination',
  stayLength: 'stayLength',
  procedureDisplayName: 'procedureDisplayName',
  locationJustification: 'locationJustification',
  admitDayBefore: 'admitDayBefore',
  admissionTime: 'admissionTime',
  includeEnema: 'includeEnema',
} as const;

export type ProposedSurgeryFieldType = AsType<typeof ProposedSurgeryField>;

/**
 * Data transfer object for field updates between UI and risk-engine
 */
type FieldUpdateDTO = {
  field: ProposedSurgeryFieldType;
  newValue: any;
  newLabel: string;
};

export default FieldUpdateDTO;
