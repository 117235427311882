import { useCallback } from 'react';
import useSecureAPI from '../auth/useSecureAPI';
import PatientAdviceMode from '../routes/patientAdvicePreview/patientAdviceMode';

type EmailTemplateType = 'admissionLetter';

export type TemplateOverrides = {
  instructionsAttached?: boolean;
};

function useCommsService() {
  const { get, post, loading, downloadFileBlob } = useSecureAPI('comms');

  const getSmsListByGpReferralId = useCallback(
    (referralUuid: string): Promise<any[]> => get(`sms/${referralUuid}`),
    [get],
  );

  /**
   * Get rendered template as PDF
   * @param previewFlags Template flag overrides for previewing
   */
  const getPDFTemplate = useCallback(
    (referralUuid: string, templateName: EmailTemplateType, previewFlags: TemplateOverrides) =>
      downloadFileBlob(`${referralUuid}/templatePdf/${templateName}`, previewFlags),
    [downloadFileBlob],
  );

  /**
   * @param previewFlags Template flag overrides for previewing
   */
  const getEmailTemplate = useCallback(
    (referralUuid: string, templateName: EmailTemplateType, previewFlags: TemplateOverrides) =>
      post<{ html: string }>(`${referralUuid}/template/${templateName}`, previewFlags).then(
        (response) => response.html,
      ),
    [post],
  );

  const checkValidTemplate = useCallback(
    (adviceMode: PatientAdviceMode) => get<boolean>(`template/${adviceMode}`),
    [get],
  );

  return {
    getPDFTemplate,
    getSmsListByGpReferralId,
    getEmailTemplate,
    checkValidTemplate,
    loading,
  };
}

export default useCommsService;
