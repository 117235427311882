import { useAuth0 } from '@auth0/auth0-react';
import { useMemo, useState } from 'react';
import { AppError, ErrorName } from '../components/errorBoundary/errorHandlingUtils';
import API_CONFIG from '../services/apiConfig';
import {
  AllowedOptions,
  checkResponseForErrors,
  handleErrResponse,
  makeOptions,
  makeOptionsFormData,
  makeQueryString,
  responseToOptionalJSON,
} from './apiUtils';

function useSecureAPI(endpoint = '') {
  const { getAccessTokenSilently, loginWithRedirect, isLoading } = useAuth0();

  const [apiLoading, setApiLoading] = useState(true);

  const loading = isLoading || apiLoading;

  const baseURL = endpoint.length > 0 ? `${API_CONFIG.baseUrl}/${endpoint}` : API_CONFIG.baseUrl;

  /**
   * Get access token
   * Redirects to login if token is expired
   */
  const getToken = async () => {
    try {
      return await getAccessTokenSilently();
    } catch (e: any) {
      if (e.error === 'missing_refresh_token' || e.error === 'invalid_grant') {
        await loginWithRedirect();
      }

      throw new AppError(
        {
          title: 'Missing or invalid refresh token',
          detail: e.message,
          errName: ErrorName.REQUIRES_LOGIN,
        },
        e,
      );
    }
  };

  /** Download file blob */
  async function downloadFileBlob(
    path: string,
    data?: Record<string, any>,
    queryParams?: Record<string, string>,
    method: 'GET' | 'POST' = 'POST',
  ) {
    setApiLoading(true);
    const token = await getToken();
    const options = makeOptions(method, token, data);
    const queryParamString = makeQueryString(queryParams);

    return fetch(`${baseURL}/${path}${queryParamString}`, options)
      .then(checkResponseForErrors)
      .then((r) => r.blob())
      .catch(handleErrResponse)
      .finally(() => setApiLoading(false));
  }

  async function downloadFileToUrl(path: string, queryParams: Record<string, string> = {}) {
    return downloadFileBlob(path, undefined, queryParams, 'GET').then((blob) =>
      URL.createObjectURL(blob),
    );
  }

  async function get<T extends NonNullable<unknown>>(
    path: string,
    reqOptions: AllowedOptions | null = null,
    queryParams: Record<string, string> = {},
  ) {
    setApiLoading(true);
    const token = await getToken();
    const options = makeOptions('GET', token, undefined, reqOptions ?? undefined);
    const queryParamString = makeQueryString(queryParams);

    return fetch(`${baseURL}/${path}${queryParamString}`, options)
      .then(checkResponseForErrors)
      .then((r) => r.json() as Promise<T>)
      .catch(handleErrResponse)
      .finally(() => setApiLoading(false));
  }

  async function put<T extends NonNullable<unknown> | void = void>(
    path: string,
    data: Record<string, any>,
  ) {
    setApiLoading(true);
    const token = await getToken();
    const options = makeOptions('PUT', token, data);

    return fetch(`${baseURL}/${path}`, options)
      .then(checkResponseForErrors)
      .then((r) => responseToOptionalJSON(r) as Promise<T>)
      .catch(handleErrResponse)
      .finally(() => setApiLoading(false));
  }

  async function post<T extends NonNullable<unknown> | void = void>(
    path: string,
    data: Record<string, any>,
  ) {
    setApiLoading(true);
    const token = await getToken();
    const options = makeOptions('POST', token, data);

    return fetch(`${baseURL}/${path}`, options)
      .then(checkResponseForErrors)
      .then((r) => responseToOptionalJSON(r) as Promise<T>)
      .catch(handleErrResponse)
      .finally(() => setApiLoading(false));
  }

  async function putFormData<T extends NonNullable<unknown> | void = void>(
    path: string,
    data: FormData,
  ) {
    setApiLoading(true);
    const token = await getToken();
    const options = makeOptionsFormData('PUT', token, data);

    return fetch(`${baseURL}/${path}`, options)
      .then(checkResponseForErrors)
      .then((r) => responseToOptionalJSON(r) as Promise<T>)
      .catch(handleErrResponse)
      .finally(() => setApiLoading(false));
  }

  async function postFormData<T extends NonNullable<unknown> | void = void>(
    path: string,
    data: FormData,
  ) {
    setApiLoading(true);
    const token = await getToken();
    const options = makeOptionsFormData('POST', token, data);

    return fetch(`${baseURL}/${path}`, options)
      .then(checkResponseForErrors)
      .then((r) => responseToOptionalJSON(r) as Promise<T>)
      .catch(handleErrResponse)
      .finally(() => setApiLoading(false));
  }

  async function deleteReq(path: string, data?: Record<string, any>) {
    setApiLoading(true);
    const token = await getToken();
    const options = makeOptions('DELETE', token, data);

    await fetch(`${baseURL}/${path}`, options)
      .then(checkResponseForErrors)
      .catch(handleErrResponse)
      .finally(() => setApiLoading(false));
  }

  return useMemo(
    () => ({
      get,
      put,
      post,
      deleteReq,
      putFormData,
      postFormData,
      downloadFileBlob,
      downloadFileToUrl,
      loading,
    }),
    [endpoint],
  );
}

export default useSecureAPI;
